<template>
  <v-container id="offer" fluid tag="section">
    <v-card>
      <v-alert color="red lighten-2" dark v-if="showNameError">
        {{ showErrorName }}
      </v-alert>
      <v-form ref="form">
        <v-container class="py-2">
          <v-row>
            <v-col cols="6" md="6">
              <v-text-field
                label="Name"
                class="purple-input"
                v-model="name"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                class="purple-input"
                label="Description"
                v-model="description"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="6">
              <v-select
                :items="stores"
                label="Select Stores"
                item-text="name"
                item-value="_id"
                v-model="storeId"
                :rules="selectRule"
                :disabled="onlyView"
              ></v-select>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                class="purple-input"
                label="Offer Amount"
                v-model="offer_amount"
                :disabled="onlyView"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="6">
              <v-text-field
                class="purple-input"
                label="Offer Percentage"
                v-model="offer_percentage"
                :disabled="onlyView"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn
                type="button"
                color="primary"
                class="mr-0"
                @click="submit"
                :loading="loading"
              >
                {{
                  onlyView == true
                    ? "Back"
                    : editOffer == true
                    ? "Update"
                    : "Submit"
                }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: ["id", "action"],
  data() {
    return {
      stores: [],
      editOffer: null,
      showNameError: false,
      showErrorName: null,
      inputRule: [(v) => !!v || "Field is required"],
      selectRule: [(v) => !!v || "Select any store"],
      onlyView: false,
      loading: false,
      name: "",
      description: "",
      storeId: "",
      offer_amount: "",
      offer_percentage: "",
    };
  },
  methods: {
    getStoreList() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "store/getall")
        .then((response) => {
          if (response.status == 200) {
            this.stores = response.data.stores;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getOfferData(id) {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "offer/edit/" + id)
        .then((response) => {
          if (response.status == 200) {
            this.name = response.data.offer.name;
            this.description = response.data.offer.description;
            this.storeId = response.data.offer.store_name[0]._id;
            this.offer_amount = response.data.offer.offer_amount;
            this.offer_percentage = response.data.offer.offer_percentage;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submit() {
      this.showError = false;
      if (this.$refs.form.validate()) {
        this.loading = true;
        let data = {
          id: this.id,
          name: this.name,
          description: this.description,
          store_id: this.storeId,
          offer_amount: this.offer_amount,
          offer_percentage: this.offer_percentage,
        };
        if (this.onlyView) {
          this.$router.push({ name: "Offers" });
        } else if (this.editOffer == true) {
          axios
            .post(process.env.VUE_APP_API_BASE_URL + "offer/update", data)
            .then((response) => {
              if (response.status == 200) {
                this.loading = false;
                this.$router.push({ name: "Offers", params: { edit: true } });
              }
            })
            .catch((error) => {
              this.loading = false;
              if (error.response.data.name != null) {
                this.showNameError = true;
                this.showErrorName = error.response.data.name[0];
              }
            });
        } else {
          axios
            .post(process.env.VUE_APP_API_BASE_URL + "offer/create", data)
            .then((response) => {
              if (response.status == 200) {
                this.loading = false;
                this.$router.push({ name: "Offers", params: { add: true } });
              }
            })
            .catch((error) => {
              this.loading = false;
              if (error.response.data.name != null) {
                this.showNameError = true;
                this.showErrorName = error.response.data.name[0];
              }
            });
        }
      }
    },
  },
  mounted() {
    this.getStoreList();
    if (this.id != undefined) {
      this.getOfferData(this.id);
      this.editOffer = true;
    } else {
      this.editOffer = false;
    }
    if (this.action == "view") {
      this.onlyView = true;
    }
  },
};
</script>